import { useMemo } from 'react';
import {
  ApolloClient, Context, getApolloContext, HttpLink, InMemoryCache,
} from '@apollo/client';
import merge from 'deepmerge';
import fetch from 'isomorphic-unfetch';
import { NextApiRequest, NextApiResponse } from 'next';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

function createApolloClient(req?: NextApiRequest) {
  let headers: NextApiRequest['headers'] = { siteid: process.env.SITE_ID };

  if (req) { headers = { ...req.headers }; }

  if (headers.host) delete headers.host;

  const API_URI = typeof window === 'undefined' ? process.env.API_URL : process.env.NEXT_PUBLIC_API_URL;

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri: API_URI, // Server URL (must be absolute)
      credentials: 'include', // Additional fetch() options like `credentials` or `headers`
      headers,
      fetch,
    }),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, req = null): ApolloClient<any> {
  const _apolloClient = apolloClient ?? createApolloClient(req);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
