/* eslint-disable react/no-array-index-key */
import {
  Box, Button, Flex, Icon,
  Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FiTrash2 } from 'react-icons/fi';
import {
  IBasket, useGetBasketQuery, useRemoveItemMutation, GetBasketDocument,
} from '../../lib/graphql';
import formatMoney from '../../utils/money';

const Basket = (): JSX.Element => {
  const [removeItemMutation] = useRemoveItemMutation({});

  let basket:IBasket = {
    subTotal: 0,
    total: 0,
  };

  const { data: basketData, loading: basketLoading } = useGetBasketQuery();

  const router = useRouter();

  if (!basketLoading && basketData?.getBasket?.basket) {
    basket = basketData?.getBasket?.basket;
  } else {
    return null;
  }

  function removeItem(itemId: number, itemType: string) {
    removeItemMutation({
      variables: { itemId, itemType },
      refetchQueries: [{ query: GetBasketDocument }],
    });
  }

  return (
    <Box>
      <Flex>
        <Button aria-label="Go to checkout" flexGrow={1} onClick={() => router.push('/checkout')} colorScheme="yellow">Go to checkout</Button>
      </Flex>
      {basket?.items?.length > 0
        ? (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th />
                <Th />
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {basket.items.map((lineItem, index) => {
                let itemName = '';
                let itemId;
                let itemType;

                if (lineItem.course) {
                  itemName = lineItem.course.courseGroup?.name
                    ? `${lineItem.course.courseGroup?.name} ${lineItem.course.name}`
                    : lineItem.course.name;
                  itemType = 'course';
                  itemId = lineItem.course.id;
                }
                if (lineItem.exam) {
                  itemName = lineItem.exam.name;
                  itemType = 'exam';
                  itemId = lineItem.exam.id;
                }
                if (lineItem.courseSession) {
                  itemName = `Taster - ${lineItem.courseSession.course.name}`;
                  itemType = 'courseSession';
                  itemId = lineItem.courseSession.id;
                }
                if (lineItem.eventTier) {
                  itemName = `Ticket: ${lineItem.eventTier.event.name} (${lineItem.eventTier.name})`
                  itemType = 'EventTier';
                  itemId = lineItem.eventTier.id;
                }

                return (
                  <Tr key={`basketItem-${index}`}>
                    <Td width="50px" padding={0}>
                      <Button
                        onClick={() => removeItem(itemId, itemType)}
                        marginRight={2}
                        variant="ghost"
                        colorScheme="gray"
                      >
                        <Icon as={FiTrash2} />
                      </Button>

                    </Td>
                    <Td>
                      <Text flex={1} alignSelf="center">
                        {itemName}
                      </Text>
                    </Td>
                    <Td textAlign="right">
                      <Text flex={1} alignSelf="center" textAlign="right">{formatMoney(lineItem.totalPrice)}</Text>
                      {lineItem.discountValue > 0 && (
                        <Text flex={1} alignSelf="center" size="sm" textAlign="right" textDecoration="line-through" color="red.400" fontStyle="oblique">{formatMoney(lineItem.price)}</Text>
                      )}
                    </Td>
                  </Tr>
                );
              })}
              <Tr>
                <Td colSpan={2} textAlign="right">
                  Subtotal
                </Td>
                <Td textAlign="right">
                  {formatMoney(basket.subTotal)}
                </Td>
              </Tr>
              { basket.discountTotal > 0 && (
                <Tr>
                  <Td colSpan={2} textAlign="right">
                    Discount
                  </Td>
                  <Td textAlign="right">
                    {formatMoney(basket.discountTotal)}
                  </Td>
                </Tr>
              )}
              { basket.creditTotal > 0 && (
                <Tr>
                  <Td colSpan={2} textAlign="right">
                    Credit
                  </Td>
                  <Td colSpan={2} textAlign="right">
                    {formatMoney(basket.creditTotal)}
                  </Td>
                </Tr>
              )}
              { basket.tax > 0 && (
              <Tr>
                <Td colSpan={2} textAlign="right">
                  VAT (20%)
                </Td>
                <Td colSpan={2} textAlign="right">
                  {formatMoney(basket.tax)}
                </Td>
              </Tr>
              )}
              { basket.payLater > 0 && (
                <Tr>
                  <Td colSpan={2} textAlign="right" fontWeight="bold">
                    Total
                  </Td>
                  <Td colSpan={2} textAlign="right" fontWeight="bold">{formatMoney(basket.total)}</Td>
                </Tr>
              )}
            </Tbody>
            <Tfoot>
              { basket.payLater > 0 ? (
                <>
                  <Tr bgColor="blue.800" borderRadius="full" textAlign="right">
                    <Th colSpan={2} color="#fff" borderRight="6px solid white" fontSize="small" fontWeight="normal">
                      Pay now
                    </Th>
                    <Th textAlign="right" color="#fff" fontSize="small" fontWeight="normal" letterSpacing="widest">{formatMoney(basket.chargeTotal)}</Th>
                  </Tr>
                  <Tr bgColor="pink.800" borderRadius="full" textAlign="right">
                    <Th colSpan={2} color="#fff" borderRight="6px solid white" fontSize="small" fontWeight="normal">
                      Pay later
                    </Th>
                    <Th textAlign="right" color="#fff" fontSize="small" fontWeight="normal" letterSpacing="widest">{formatMoney(basket.payLater)}</Th>
                  </Tr>
                </>
              ) : (
                <Tr bgColor="blue.600" borderRadius="full" textAlign="right">
                  <Th colSpan={2} color="#fff" borderRight="6px solid white" fontSize="small" fontWeight="normal">
                    Total
                  </Th>
                  <Th textAlign="right" color="#fff" fontSize="small" fontWeight="normal" letterSpacing="widest">{formatMoney(basket.total)}</Th>
                </Tr>
              )}
            </Tfoot>
          </Table>
        )
        : (
          <Box padding={6} alignItems="center">
            <Text textAlign="center">Empty basket</Text>
          </Box>
        )}
    </Box>
  );
};

export default Basket;
